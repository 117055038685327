<div class="add-location-container">
    @if (createScreenSkeletonVisible()) {
        <app-locations-skeleton [props]="SKELETON_TYPE.DESCRIPTION"></app-locations-skeleton>
  } @else {
      <div class="section-header">
          <div class="heading">
              <i class="ph ph-caret-left" (click)="onPressedBack()"></i> Create Cluster
          </div>
          <i class="ph-bold ph-dots-three-vertical"></i>
      </div>
      <div class="add-location-wrapper">
        <div class="stepper-sidebar">
          <app-stepper [props]="stepperProps()"></app-stepper>
        </div>
        <form class="upload-sidebar" [formGroup]="filterForm">
          <div class="heading-wrapper font-weight-500">{{ !reviewCluster() ? 'Upload Locations' : 'Cluster' }}</div>
          <div class="sub-heading-wrapper font-weight-500">{{ !reviewCluster() ? 'Add and Organize Locations' : 'Review & Modify any locations'}}</div>
          @if(!reviewCluster()){
            <div class="info-wrapper">
              <div class="info-heading-wrap"><i class="ph ph-warning-circle"></i>
                <div>
                  <div class="font-weight-600">Info</div>
                  <ol class="font-weight-400">
                    <li>1. Download the list of locations.</li>
                    <li>2. Keep only the locations you wish to include in the cluster.</li>
                    <li>3. Save and upload the file below.</li>
                    <li>4. You can correct any errors if found by downloading and correcting them.</li>
                    <li>5. Check the locations & create a cluster.</li>
                  </ol>
                </div>
              </div>
            </div>
            <app-button [props]="{theme: BUTTON_THEME.BLUE,leadingIcon:'ph-bold ph-download-simple', buttonText:'Download List of Locations',padding:'16px'}" (onClick)="downloadAllLocation()"/>
            @if (isLocationFileError()) {
              <div class="error-wrap">
                <app-button [props]="tryAgainButtonProps()" (onClick)="toggleLocationError()"/>
                <div class="download-error-wrap">
                  <app-button [props]="downloadErrorsButtonProps()" (onClick)="saveFile()"/>
                  <i class="ph-fill ph-warning-circle"></i>
                </div>
              </div>
            }
            @else {
              <div class="upload-wrap font-weight-600">Upload</div>
              <app-file-uploader [props]="{allowedFormats: [FILE_TYPES.XLSX], fileLimit: 1, disableDeleteFile: isLoading() }" (onFileAdded)="handleFileUpload($event)"></app-file-uploader>
            }
        }@else{
            <div class="name-group">
                <div class="label font-weight-500">Cluster Name*</div>
                <app-text-input formControlName="name" [props]="{placeholder: 'Name of Cluster', maxLength: 50, errorTooltip: 'Input must contain characters only, numbers and special characters are not allowed. Spaces are allowed in the input except at beginning'}" />
            </div>
            <app-multi-select [props]="locationDropdownProps()" formControlName="locations" (onOptionSearch)="onLocationSearch($event)"/>
            <div class="selected-locations-wrap">
              @if(locations().length == 0){
               <div class="placeholder-wrap">Please select locations.....</div>
              }
              @for(location of locations(); track location._id){
                <div class="location-bar">
                    <div class="location-wrap font-weight-400">{{location.name}}</div><i class="ph ph-x" (click)="onDeleteLocation(location._id)"></i>
                </div>
              }
            </div>
            <div class="location-animation-wrap">
                <div class="star-animation-wrap"><img width="100px" height="100px" [src]="getStarsBasedOnLocationCount()"></div>
                <div>
                  <div class="location-count-wrap font-weight-600">{{locations().length}} Locations Added</div>
                  <div class="tip-wrap">Tip: Keeping your clusters small can help improve work-efficiency</div>
                </div>
            </div>
        }              
      </form> 
      </div> 
      <div class="footer-wrapper">
          <app-button [props]="{theme: BUTTON_THEME.NONE, buttonText:'Cancel', color:'var(--errorColor)'}" (onClick)="onPressedBack()"/>
          @if(!reviewCluster()){
            @if(locations().length == 0){
            <app-button [props]="{theme: BUTTON_THEME.BORDER,leadingIcon:'ph ph-skip-forward', buttonText:'Skip', disabled: isLoading()}" (onClick)="handleSkipAndNextButton()"/>
          }@else {
            <app-button [props]="{theme: BUTTON_THEME.BLACK,trailingIcon:'ph ph-caret-right', buttonText:'Next'}" (onClick)="handleSkipAndNextButton()"/>
          }
          }@else {
            <div class="buttons-wrap">
              <app-button [props]="{theme: BUTTON_THEME.BORDER, buttonText:'Previous',leadingIcon:'ph ph-caret-left'}" (onClick)="handleSkipAndNextButton()" />
              <app-button [props]="{theme: BUTTON_THEME.BLUE, buttonText:'Create Cluster',leadingIcon:'ph-bold ph-polygon'}"(onClick)="onSavePressed()"/>
            </div>
          }
      </div>
    }
  </div> 
